import React from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import { DealsPageTemplate } from '@templates/deals/deals';
import { PAGE_DATA } from '@shared/constants/pages/page-builder-deals';
import { dealsTheme } from '@theme';

const PageBuilderDealsHandler = (props) => {
  const {
    data: {
      dealsJson: {
        header: { title, subtitle, image },
      },
    },
  } = props;
  return (
    <ThemeProvider theme={dealsTheme}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <DealsPageTemplate
        {...props}
        {...PAGE_DATA({
          variant: 'dark',
          title,
          subtitle,
          image,
          images: props.data.allFile.edges,
        })}
      />
    </ThemeProvider>
  );
};

export default PageBuilderDealsHandler;

export const pbDealsQuery = graphql`
  query PbDealsQuery($language: String!, $slug: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "deals", "plans", "featureTabs", "campaigns"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    dealsJson(slug: { eq: $slug }) {
      header {
        title {
          text
          showDynamicText
        }
        subtitle {
          text
          showDynamicText
        }
        image
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "deals/money-back-guarantee.png"
            "deals/review-logos/vpn-mentor.png"
            "deals/review-logos/top10-vpn.png"
            "deals/review-logos/cybernews.png"
            "deals/review-logos/the-best-vpn-deals.png"
            "deals/review-logos/vpn-pro.png"
            "deals/review-logos/vpn-alert.png"
            "features/streaming-dark.png"
            "features/privacy-dark.png"
            "features/speed-dark.png"
            "features/security-dark.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
